import { inject, ref, computed } from 'vue'
import { useCountryStore } from './country'

export const useSettingsStore = defineStore('settings-store', () => {
  const countryStore = useCountryStore()
  const fetchApi = inject('fetchApi') as Function
  const windowWidth = ref<number>(window.innerWidth)
  const isMobile = computed(() => windowWidth.value < 767)
  const isTablet = computed(() => windowWidth.value < 992)
  const jobCategories = ref({})
  const jobSkills = ref({})

  function getJobCategories() {
    return fetchApi('/api/v3/job-categories', countryStore.backend)
      .then(res => res.json())
      .then((response:any) => {
        jobCategories.value.data = response.data.map((item) => {
          const modifiedItem = item
          modifiedItem.icon_path = item.icon_path.replace('white-line', 'no-fill')
          return modifiedItem
        })
      })
  }

  function getJobSkills() {
    return fetchApi('/api/v3/skills', countryStore.backend)
      .then(res => res.json())
      .then((response:any) => {
        jobSkills.value = response
      })
  }

  function setWidth(number:number) {
    windowWidth.value = number
  }
  return { windowWidth, isMobile, isTablet, setWidth, getJobCategories, getJobSkills, jobCategories, jobSkills }
})
